var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form',{ref:"formLogin",staticClass:"user-layout-login",attrs:{"id":"formLogin","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-tabs',{attrs:{"activeKey":_vm.customActiveKey,"tabBarStyle":{ textAlign: 'center', borderBottom: 'unset' }},on:{"change":_vm.handleTabClick}},[_c('a-tab-pane',{key:"tab1",attrs:{"tab":"账号密码登录"}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'userName',
                { rules: [{ required: true, message: '请输入用户名' }] },
              ]),expression:"[\n                'userName',\n                { rules: [{ required: true, message: '请输入用户名' }] },\n              ]"}],attrs:{"size":"large","type":"text","placeholder":"请输入用户名"}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'password',
                { rules: [{ required: true, message: '请输入密码' }] },
              ]),expression:"[\n                'password',\n                { rules: [{ required: true, message: '请输入密码' }] },\n              ]"}],attrs:{"size":"large","type":"password","autocomplete":"false","placeholder":"请输入密码"}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{staticClass:"gutter-row",attrs:{"span":14}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['VerifyCode', { rules: [{ required: true, message: '请输入验证码' }] }]),expression:"['VerifyCode', { rules: [{ required: true, message: '请输入验证码' }] }]"}],attrs:{"size":"large","type":"text","autocomplete":"false","placeholder":"请输入验证码"}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"safety-certificate"},slot:"prefix"})],1)],1)],1),_c('a-col',{staticClass:"gutter-row",attrs:{"span":10}},[_c('img',{staticClass:"getCaptcha",attrs:{"src":_vm.codeImg,"alt":"看不清，换一张"},on:{"click":_vm.handleCaptcha}})])],1),_c('a-form-item',[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['savePwd', { valuePropName: 'checked' }]),expression:"['savePwd', { valuePropName: 'checked' }]"}]},[_vm._v("记住密码")])],1),_c('a-form-item',[_c('input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['Sign']),expression:"['Sign']"}],attrs:{"type":"hidden"}})])],1)],1),_c('a-form-item',[_c('a-button',{staticClass:"login-button",attrs:{"size":"large","type":"primary","htmlType":"submit"}},[_vm._v("确定")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }